import React from 'react';
import { Button } from '../';
import { navigate } from "gatsby"

const Center = ({ price, title, link }) => {
  function navigateTo() {
    navigate(link)
  }

  return (
    <div>
      <p className="text-4xl xl:text-5xl font-bold tracking-widest leading-none">{title}</p>
      <p>FROM <span>£{price}</span></p>
      <Button
        onClick={navigateTo}
        title="Shop Now"
      />
       <p className='pt-5'>
          <div>Mix between beauty and sound design.</div>
          <div>Making life more comfortable through our headphones.</div>
       </p>
    </div>
  )
}

export default Center